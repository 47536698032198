export class RecipientModel {
  static fromDTO(dto) {
    return new RecipientModel(dto.firstName, dto.lastName, dto.email, dto.notificationId, dto.isSignatureRequired, dto.hasSigned, "https://".concat(window.location.host, "/notification/").concat(dto.notificationId));
  }
  constructor(firstName, lastName, email, notificationId, isSignatureRequired, hasSigned, notificationURL) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.notificationId = notificationId;
    this.isSignatureRequired = isSignatureRequired;
    this.hasSigned = hasSigned;
    this.notificationURL = notificationURL;
  }
  fullName() {
    return "".concat(this.firstName, " ").concat(this.lastName);
  }
}