import { defaultAgreementTitle } from "../../constants";
import { ErrorResponse } from "../../infra/ErrorResponse";
export const getUICompanyName = draft => {
  return draft.isSample ? 'Anchor (Sample)' : draft.contactDetails ? draft.contactDetails.companyName : '';
};
const sortById = (a, b) => a.id < b.id ? -1 : 1;
const sortByCompanyName = (a, b) => {
  var _a$contactDetails, _b$contactDetails;
  const contactCompanyNameA = ((_a$contactDetails = a.contactDetails) === null || _a$contactDetails === void 0 ? void 0 : _a$contactDetails.companyName) || '';
  const contactCompanyNameB = ((_b$contactDetails = b.contactDetails) === null || _b$contactDetails === void 0 ? void 0 : _b$contactDetails.companyName) || '';
  const noDataA = !contactCompanyNameA && !a.title;
  const noDataB = !contactCompanyNameB && !b.title;
  if (noDataA && noDataB) {
    return 0;
  }
  if (noDataA) {
    return 1;
  }
  if (noDataB) {
    return -1;
  }
  const nameA = contactCompanyNameA.toLowerCase() + (a.title ? " (".concat(a.title.toLowerCase(), ")") : '');
  const nameB = contactCompanyNameB.toLowerCase() + (b.title ? " (".concat(b.title.toLowerCase(), ")") : '');
  if (nameA < nameB) {
    return -1;
  } else if (nameA > nameB) {
    return 1;
  } else {
    return 0;
  }
};
const sortByModifiedBy = (a, b) => {
  const modifiedByA = a.lastModifiedBy ? "".concat(a.lastModifiedBy.firstName, " ").concat(a.lastModifiedBy.lastName) : '';
  const modifiedByB = b.lastModifiedBy ? "".concat(b.lastModifiedBy.firstName, " ").concat(b.lastModifiedBy.lastName) : '';
  if (modifiedByA < modifiedByB) {
    return -1;
  } else if (modifiedByA > modifiedByB) {
    return 1;
  } else {
    return 0;
  }
};
const sortByLastModifiedOn = (a, b) => b.lastModifiedOn - a.lastModifiedOn;
const sortFunctions = {
  0: isAscending => (a, b) => {
    const retval = sortByCompanyName(a, b) || sortByModifiedBy(a, b) || sortById(a, b);
    return isAscending ? -retval : retval;
  },
  1: isAscending => (a, b) => {
    const retval = sortByModifiedBy(a, b) || sortByCompanyName(a, b) || sortById(a, b);
    return isAscending ? -retval : retval;
  },
  2: isAscending => (a, b) => {
    const retval = sortByLastModifiedOn(a, b) || sortById(a, b);
    return isAscending ? -retval : retval;
  }
};
function textSearchDraft(search, draft) {
  return "".concat(getUICompanyName(draft), " ").concat(draft.title).toLowerCase().includes(search);
}
export function filterDrafts(data, search, sort) {
  const filtered = data ? data.filter(payout => {
    const normalizedSearch = search ? search.trim().toLowerCase() : search;
    const passesSearch = !normalizedSearch || textSearchDraft(normalizedSearch, payout);
    return passesSearch;
  }) : [];
  if (sort) {
    filtered.sort(sortFunctions[sort.colIndex](sort.sort === 'asc'));
  }
  return {
    drafts: filtered,
    draftsMap: new Map(filtered.map(draft => [draft.id, draft]))
  };
}
export function bulkExternallySignErrorText(draft, error) {
  const {
    text
  } = new ErrorResponse(error).createRelationshipErrorText();
  if (!draft) return null;
  const prefix = draft.contactDetails ? "".concat(draft.contactDetails.companyName, " (").concat(draft.contactDetails.email, ")") : draft.title || defaultAgreementTitle;
  return "".concat(prefix, " - ").concat(text);
}