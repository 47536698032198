import { useOverlayContext, useIoContext, useStoreActions } from "../contexts";
import { handleRequest } from "../infra/utils";
import { ErrorText, routes } from "../constants";
import { useOpenCopyLinkModal } from "./useOpenCopyLinkModal/useOpenCopyLinkModal";
import { ErrorResponse, ServerError } from "../infra/ErrorResponse";
export function useCopyProposalLink() {
  const overlayContext = useOverlayContext();
  const {
    api,
    logBI
  } = useStoreActions();
  const {
    openCopyLinkForProposal
  } = useOpenCopyLinkModal();
  const {
    copyToClipboard
  } = useIoContext();
  async function getAgreementNotificationLink(agreementId) {
    try {
      const notificationId = await api.relationship.getSingleUseApprovalLink(agreementId);
      return "https://".concat(window.location.host).concat(routes.notification(notificationId));
    } catch (err) {
      handleGetLinkError(err);
      throw err;
    }
  }
  async function getAgreementNotificationLinks(agreementId) {
    try {
      const response = await api.relationship.getProposalLinks(agreementId);
      return response;
    } catch (err) {
      handleGetLinkError(err);
      throw err;
    }
  }
  function handleGetLinkError(err) {
    const e = new ErrorResponse(err);
    const message = e.hasServerError(ServerError.RelationshipAlreadyApproved) ? 'Failed to generate link, the proposal was already approved' : 'Failed to generate link, please try again';
    overlayContext.showErrorToast(message, 'agreement-link-error-toast');
  }
  async function copyNotificationUrl(notificationUrl) {
    try {
      await copyToClipboard(notificationUrl);
      overlayContext.showSuccessToast('Link copied to clipboard', 'copy-link-success');
    } catch (e) {
      // e.g. fails if the document loses focus.
      overlayContext.showErrorToast('Failed to copy link to clipboard');
    }
  }
  async function copyAgreementLink(_ref) {
    let {
      agreementId,
      vendorId,
      customerId,
      progressStatus,
      withGlobalLoader
    } = _ref;
    logBI(10589, {
      vendorId,
      clientId: customerId,
      agreementStatus: progressStatus
    });
    const loaderName = 'copyAgreementLink';
    withGlobalLoader && overlayContext.startLoader(loaderName);
    const response = await handleRequest(getAgreementNotificationLinks(agreementId));
    withGlobalLoader && overlayContext.stopLoader(loaderName);
    if (!response.success) {
      overlayContext.showErrorToast(ErrorText.UnexpectedErrorTryAgain);
      return;
    }
    const key = 'recipients';
    await openCopyLinkForProposal({
      [key]: response.data,
      testid: 'copy-direct-link-copy'
    });
  }
  return {
    getAgreementNotificationLink,
    getAgreementNotificationLinks,
    copyAgreementLink,
    copyNotificationUrl
  };
}