import range from 'lodash/range';
export function getProposalExpiryRange() {
  return {
    minDays: 1,
    maxDays: 90
  };
}
export function getAllProposalExpiryDays() {
  const {
    minDays,
    maxDays
  } = getProposalExpiryRange();
  return range(minDays, maxDays + 1);
}
export function isProposalExpiryValid(value) {
  const {
    minDays,
    maxDays
  } = getProposalExpiryRange();
  return value >= minDays && value <= maxDays;
}