import { useMemo } from 'react';
import { useOverlayContext, useStoreActions } from "../../contexts";
import { handlePromise, sendErrReport } from "../../infra/utils";
export function useSendReminder() {
  const {
    api
  } = useStoreActions();
  const {
    showSuccessToast,
    showErrorToast
  } = useOverlayContext();
  async function sendReminder(agreement) {
    const result = await handlePromise(api.relationship.sendProposalApprovalReminder(agreement.id));
    if (result.success) {
      showSuccessToast("Reminder successfully sent");
    } else {
      showErrorToast('Failed to send reminder');
      sendErrReport('Failed to send reminder', result.error);
    }
  }
  return useMemo(() => ({
    sendReminder
  }), []);
}