import { isAfter, startOfDay } from 'date-fns';
import { renderBillingSettingsSection } from "../../components/ServiceDetails/BillingSettingsSection";
import { BillingTriggerType } from "../../types/api.model";
import { serviceBillingModel, serviceCostModel, serviceModel, timeModel } from "../../models";
import { RecurrencePeriodTypes } from "../../models/service-billing";
import { getUnitName } from "../unit-name.utils";
import { numberAndUnit } from "../../infra/utils";
import compact from 'lodash/compact';
import { utils } from '..';
import { fromDatestampObject } from "../../models/time";
function costDetails(_ref) {
  let {
    service
  } = _ref;
  const {
    cost,
    billing
  } = service;
  if (serviceCostModel.isVariablePrice(cost) && cost.unitCap) {
    if (cost.unitCap.isZero()) {
      return 'Charging this service will require approval';
    } else {
      const unitText = numberAndUnit(cost.unitCap.toNumber(), getUnitName(cost.unitName));
      if (serviceBillingModel.isRecurring(billing)) {
        const recurrenceTextMap = {
          [RecurrencePeriodTypes.Weekly]: 'week',
          [RecurrencePeriodTypes.BiWeekly]: 'two weeks',
          [RecurrencePeriodTypes.Monthly]: 'month',
          [RecurrencePeriodTypes.Quarterly]: 'quarter',
          [RecurrencePeriodTypes.Yearly]: 'year'
        };
        const recurrenceText = recurrenceTextMap[billing.recurrencePeriod];
        return "Charging more than ".concat(unitText, " per ").concat(recurrenceText, " for this service will require approval");
      } else {
        return "Charging more than ".concat(unitText, " for this service will require approval");
      }
    }
  } else if (serviceCostModel.isRangePrice(cost)) {
    const maxPrice = utils.formatDollarAmount(serviceCostModel.applyDiscount(cost));
    return "Charging this service over ".concat(maxPrice, " will require approval");
  } else {
    return null;
  }
}
function billingSettingsSection(_ref2) {
  let {
    agreementData,
    isReadOnly,
    testid,
    service,
    skin,
    shouldRenderBillingBullet = true,
    onBillUpfrontModeChange = () => {},
    onDayOfMonthChange = () => {},
    onDayOfWeekChange = () => {}
  } = _ref2;
  return renderBillingSettingsSection({
    service,
    testid: testid || '',
    isReadOnly,
    isActiveAgreement: agreementData.isActiveAgreement(),
    skin,
    shouldRenderBillingBullet,
    onDayOfMonthChange,
    onDayOfWeekChange,
    onBillUpfrontModeChange
  });
}
function billingDetails(params) {
  const {
    service,
    agreementData,
    now
  } = params;
  const {
    billing,
    cost
  } = service;
  const {
    effectiveDate
  } = agreementData;
  const isNewServiceInAmendment = serviceModel.isAwaitingApproval(service.status) && agreementData.isActiveAgreement();
  const isFutureEffectiveDate = !!effectiveDate && isAfter(startOfDay(fromDatestampObject(effectiveDate)), startOfDay(now));
  switch (billing.trigger) {
    case BillingTriggerType.OnApproval:
      {
        const suffix = isNewServiceInAmendment ? ' after the amendment is saved' : isFutureEffectiveDate ? ' on the effective date of the agreement' : ' on proposal acceptance';
        return "Billing is done once".concat(suffix);
      }
    case BillingTriggerType.OneTimeManual:
      return 'Can be billed once';
    case BillingTriggerType.RepeatableManual:
      {
        if (serviceCostModel.isVariablePrice(cost)) {
          return null;
        }
        const recurrenceTextMap = {
          [RecurrencePeriodTypes.Weekly]: 'per week',
          [RecurrencePeriodTypes.BiWeekly]: 'every two weeks',
          [RecurrencePeriodTypes.Monthly]: 'per month',
          [RecurrencePeriodTypes.Quarterly]: 'per quarter',
          [RecurrencePeriodTypes.Yearly]: 'per year'
        };
        const recurrenceText = recurrenceTextMap[billing.recurrencePeriod];
        return "This service can be billed once ".concat(recurrenceText);
      }
    case BillingTriggerType.Ongoing:
      {
        if (billing.isBilledUpfront && !billing.billUpfrontState.skipBillingOnAcceptance) {
          const suffix = isNewServiceInAmendment ? 'immediately after the amendment is saved' : isFutureEffectiveDate ? 'on the effective date of the agreement' : 'on proposal acceptance';
          return "This service will be billed ".concat(suffix);
        } else {
          return null;
        }
      }
  }
}
function prorated(_ref3) {
  let {
    service
  } = _ref3;
  const {
    billing
  } = service;
  if (serviceBillingModel.isOngoing(billing) && billing.isProrated) {
    return 'The first payment for this service is prorated';
  }
  return null;
}
function limitedTimes(_ref4) {
  let {
    service
  } = _ref4;
  const {
    billing
  } = service;
  if (serviceBillingModel.isOngoing(billing) && serviceModel.maxChargesEnabled(service)) {
    return "This service can be billed ".concat(serviceBillingModel.formatMaxChargesLimit(billing));
  }
  return null;
}
function paused(_ref5) {
  let {
    service,
    agreementData
  } = _ref5;
  const {
    billing
  } = service;
  if (!agreementData.isActiveAgreement() && serviceBillingModel.isOngoing(billing) && billing.isPaused) {
    return 'Billing for this service will commence only after it has been activated by the service provider';
  }
  return null;
}
function priceIncrease(_ref6) {
  let {
    service
  } = _ref6;
  const {
    cost
  } = service;
  const priceIncrease = serviceCostModel.priceIncrease(cost);
  if (priceIncrease.exists()) {
    const increaseOn = priceIncrease.increaseOn();
    const suffix = increaseOn ? "starting on ".concat(timeModel.formatDateLong(increaseOn)) : 'on agreement anniversary';
    const increaseBy = priceIncrease.isNonPositiveIncrease() ? 'x' : "".concat(priceIncrease.percentIncrease(), "%");
    return "Price increases by ".concat(increaseBy, " every year ").concat(suffix);
  }
  return null;
}
export function getServiceBulletPoints(params) {
  return {
    bullets: compact([billingSettingsSection(params), billingDetails(params), costDetails(params), prorated(params), limitedTimes(params), priceIncrease(params)]),
    pausedCallout: paused(params)
  };
}