function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { useOverlayContext, useStoreActions } from "../../contexts";
import { generateUniqueId } from 'seagull/utils/generateUniqueId';
import { useRedirectToProposal } from "../useCreateAgreement";
import { mapAgreementToDraft } from "./mapAgreementToDraft";
import { makeSentryIgnoredError } from "../../components/ErrorBoundaryPage";
import { draftModel } from "../../models/draft";

/* Copies an existing relationship (agreement or proposal) to a new draft */
export const useDuplicateRelationship = () => {
  const {
    api,
    fetchLegalTerms
  } = useStoreActions();
  const {
    startLoader,
    stopLoader,
    showErrorToast
  } = useOverlayContext();
  const {
    openCreationModal,
    redirectToProposal
  } = useRedirectToProposal();

  /**
   * Fetches a list of legal-terms doc-IDs that exist in the library of the current business.
   * An agreement/draft/proposal can contain a legal-terms doc from the library or a custom
   * one that's not from the library.
   */
  async function fetchLegalTermsDocIds() {
    const legalTerms = await fetchLegalTerms();
    if (legalTerms) {
      return legalTerms.map(doc => doc.id);
    } else {
      throw makeSentryIgnoredError('legalTermsError');
    }
  }
  async function loadAndGo(type, action) {
    let opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    try {
      startLoader("duplicate-".concat(type));
      return Promise.resolve(await action());
    } catch (e) {
      if (opts.onError) {
        opts.onError(e);
      } else {
        showErrorToast("Failed to duplicate ".concat(type, ", please try creating a new one instead"));
      }
      return Promise.reject();
    } finally {
      stopLoader("duplicate-".concat(type));
    }
  }
  async function getRelationship(relationshipId) {
    return await api.relationship.get(relationshipId);
  }
  async function duplicateRelationshipAndRedirect(relationshipId) {
    let strategy = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'duplicate-agreement';
    if (strategy === 'withdraw-to-edit') {
      return loadAndGo('agreement', async () => {
        const [currentAgreement, legalTermsDocIds] = await Promise.all([getRelationship(relationshipId), fetchLegalTermsDocIds()]);
        const draftData = mapAgreementToDraft(currentAgreement, {
          strategy,
          legalTermsDocIds
        });
        await api.draft.save(draftData);
        await redirectToProposal('agreement', draftData.id);
        return draftData.id;
      });
    } else {
      openCreationModal(async () => {
        return loadAndGo('agreement', async () => {
          const [currentAgreement, legalTermsDocIds] = await Promise.all([getRelationship(relationshipId), fetchLegalTermsDocIds()]);
          const mappingOptions = {
            strategy,
            legalTermsDocIds
          };
          if (currentAgreement != null) {
            const draftData = mapAgreementToDraft(currentAgreement, mappingOptions);
            await api.draft.save(draftData);
            return draftData.id;
          } else {
            return Promise.reject('error fetching agreement');
          }
        });
      });
    }
  }
  async function duplicateRelationship(relationshipId) {
    let opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const {
      strategy = 'duplicate-agreement'
    } = opts;
    return loadAndGo('agreement', async () => {
      const [currentAgreement, legalTermsDocIds] = await Promise.all([getRelationship(relationshipId), fetchLegalTermsDocIds()]);
      const draftData = mapAgreementToDraft(currentAgreement, {
        strategy,
        legalTermsDocIds
      });
      await api.draft.save(draftData);
      return draftData.id;
    }, opts);
  }
  async function duplicateDraftFromId(draftId) {
    openCreationModal(async () => {
      return loadAndGo('draft', async () => {
        const res = await api.draft.getById(draftId);
        const id = generateUniqueId('draft');
        const draftToSave = draftModel.toDTO(_objectSpread(_objectSpread({}, res), {}, {
          id,
          effectiveDate: null
        }));
        await api.draft.save(draftToSave);
        return id;
      });
    });
  }
  return {
    duplicateRelationshipAndRedirect,
    duplicateDraftFromId,
    duplicateRelationship
  };
};