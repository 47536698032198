// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hzWLT{display:flex;flex-direction:column}.oXeWG{margin-bottom:12px;margin-top:12px}.U6rJR{margin-bottom:16px}.DwUly{padding-bottom:10px;padding-top:10px}.VHT4Y{padding-top:40px;align-self:flex-start}.Q4_pp{margin-top:80px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"successPageContainer": `hzWLT`,
	"header": `oXeWG`,
	"pendingAdditionalSignatures": `U6rJR`,
	"callout": `DwUly`,
	"awaitingRecipients": `VHT4Y`,
	"createVendorServiceTextBlock": `Q4_pp`
};
export default ___CSS_LOADER_EXPORT___;
