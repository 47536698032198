import { useMemo, useState } from 'react';
import { useOverlayContext, useIoContext, useStoreActions } from "../contexts";
import { handlePromise, wait } from "../infra/utils";
import { useStateInRef } from 'seagull';
import { PlaidMode } from "./usePlaidState";
const linkTokenLoader = 'linkToken';
const verifyLoader = 'verify';
export function useVerifyPlaidBankAccount() {
  const overlayContext = useOverlayContext();
  const {
    api,
    logBI
  } = useStoreActions();
  const {
    usePlaidLink
  } = useIoContext();
  const [linkToken, setLinkToken] = useState(null);
  const [data, setData] = useState({});
  const options = useMemo(() => {
    return {
      token: linkToken,
      async onExit(error, _metadata) {
        if (error && error.error_code === 'TOO_MANY_VERIFICATION_ATTEMPTS' && data.fundingSourceId) {
          overlayContext.startLoader(verifyLoader);
          await handlePromise(api.plaid.failedVerification(data.fundingSourceId));
          overlayContext.stopLoader(verifyLoader);
          logBI(10821, {
            plaid: true
          });
          data.onVerifyFailed && data.onVerifyFailed();
          return;
        }
      },
      async onSuccess() {
        if (!data.fundingSourceId) return;
        overlayContext.startLoader(verifyLoader);
        await handlePromise(api.plaid.verifyMicroDeposit(data.fundingSourceId));
        overlayContext.stopLoader(verifyLoader);
        logBI(10820, {
          plaid: true
        });
        data.onVerify && data.onVerify();
      }
    };
  }, [linkToken, data]);
  const getPlaidLink = useStateInRef(usePlaidLink(options));
  return async function verifyPlaidBankAccount(options) {
    const {
      fundingSourceId,
      relationshipId
    } = options;
    if (!fundingSourceId) return;
    logBI(10823, {
      plaid: true
    });
    setData(options);
    overlayContext.startLoader(linkTokenLoader);
    const token = await handlePromise(api.plaid.getLinkToken(PlaidMode.Customer, {
      fundingSourceId,
      relationshipId
    }));
    if (!token.success) {
      overlayContext.stopLoader(linkTokenLoader);
      overlayContext.showErrorToast('Failed to initialize, please try again.', 'link-token-error');
    } else {
      setLinkToken(token.data);
      while (!getPlaidLink().ready) {
        await wait(50);
      }
      overlayContext.stopLoader(linkTokenLoader);
      getPlaidLink().open();
    }
  };
}