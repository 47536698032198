function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { Decimal } from 'decimal.js';
import capitalize from 'lodash/capitalize';
import { getUnitName } from "../../../utils/unit-name.utils";
import { BillingTriggerType } from "../../../types/api.model";
import { utils } from "../../../utils";
export const makeSectionsFromServices = services => {
  const onApproval = {
    id: 'onApproval',
    services: [],
    subtitle: 'Once, On Agreement Approval',
    category: 'oneTime'
  };
  const oneTimeManual = {
    id: 'oneTimeManual',
    services: [],
    subtitle: 'Once, On Completion',
    category: 'oneTime'
  };
  const weekly = {
    id: 'weekly',
    services: [],
    subtitle: 'Every Week',
    category: 'recurring'
  };
  const biWeekly = {
    id: 'biWeekly',
    services: [],
    subtitle: 'Every Two Weeks',
    category: 'recurring'
  };
  const monthly = {
    id: 'monthly',
    services: [],
    subtitle: 'Every Month',
    category: 'recurring'
  };
  const quarterly = {
    id: 'quarterly',
    services: [],
    subtitle: 'Every Quarter',
    category: 'recurring'
  };
  const yearly = {
    id: 'yearly',
    services: [],
    subtitle: 'Every Year',
    category: 'recurring'
  };
  const variable = {
    id: 'variable',
    services: [],
    category: 'variable'
  };
  const optional = {
    id: 'optional',
    services: [],
    category: 'optional'
  };
  services.forEach(service => {
    const {
      name,
      id,
      description,
      cost,
      billing
    } = service;
    const isOptional = service.participation.type === 'optional';
    const baseServiceData = {
      name,
      id,
      description
    };
    const pushServiceToSection = (section, s) => {
      const actualSection = isOptional ? optional : section;
      actualSection.services.push(s);
      if (isOptional) {
        section.hasOptionalServices = true;
      } else if (section.category !== 'variable') {
        const finalPrice = utils.applyDiscount(cost.price, cost.discount);
        actualSection.sum = new Decimal(actualSection.sum || 0).add(finalPrice);
      }
    };
    if (cost.pricingType === 'variable') {
      const formattedCost = utils.formatNumberWithCommas(utils.applyDiscount(cost.price, cost.discount));
      const unit = capitalize(getUnitName(cost.unitName));
      const variablePriceText = "$".concat(formattedCost, "/").concat(unit);
      const s = isOptional ? baseServiceData : _objectSpread(_objectSpread({}, baseServiceData), {}, {
        variablePriceText
      });
      pushServiceToSection(variable, s);
    } else if (cost.pricingType === 'range') {
      const min = cost.minPrice ? utils.formatNumberWithCommas(utils.applyDiscount(cost.minPrice, cost.discount)) : null;
      const max = utils.formatNumberWithCommas(utils.applyDiscount(cost.maxPrice, cost.discount));
      const variablePriceText = min ? "$".concat(min, "\u2014$").concat(max) : "Up to $".concat(max);
      const s = isOptional ? baseServiceData : _objectSpread(_objectSpread({}, baseServiceData), {}, {
        variablePriceText
      });
      pushServiceToSection(variable, s);
    } else {
      const section = (() => {
        if (billing.trigger === BillingTriggerType.OnApproval) return onApproval;
        if (billing.trigger === BillingTriggerType.OneTimeManual) return oneTimeManual;
        if ((billing.trigger === BillingTriggerType.Ongoing || billing.trigger === BillingTriggerType.RepeatableManual) && billing.recurrencePeriod === 'weekly') return weekly;
        if ((billing.trigger === BillingTriggerType.Ongoing || billing.trigger === BillingTriggerType.RepeatableManual) && billing.recurrencePeriod === 'biweekly') return biWeekly;
        if ((billing.trigger === BillingTriggerType.Ongoing || billing.trigger === BillingTriggerType.RepeatableManual) && billing.recurrencePeriod === 'monthly') return monthly;
        if ((billing.trigger === BillingTriggerType.Ongoing || billing.trigger === BillingTriggerType.RepeatableManual) && billing.recurrencePeriod === 'quarterly') return quarterly;
        if ((billing.trigger === BillingTriggerType.Ongoing || billing.trigger === BillingTriggerType.RepeatableManual) && billing.recurrencePeriod === 'yearly') return yearly;
      })();
      section && pushServiceToSection(section, baseServiceData);
    }
  });
  return [onApproval, oneTimeManual, weekly, biWeekly, monthly, quarterly, yearly, variable, optional].filter(_ref => {
    let {
      services
    } = _ref;
    return services.length > 0;
  });
};