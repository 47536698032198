import { useMemo, useState } from 'react';
import { useOverlayContext, useIoContext, useStoreActions } from "../contexts";
import { handlePromise } from "../infra/utils";
import { useStateInRef } from 'seagull';
import { PlaidMode } from "./usePlaidState";
const linkTokenLoader = 'linkToken';
export function useReloginPlaidBankAccount() {
  const overlayContext = useOverlayContext();
  const {
    api,
    logBI
  } = useStoreActions();
  const {
    usePlaidLink
  } = useIoContext();
  const [linkToken, setLinkToken] = useState(null);
  const [data, setData] = useState({});
  const options = useMemo(() => {
    return {
      token: linkToken,
      onLoad() {
        overlayContext.stopLoader(linkTokenLoader);
        getPlaidLink().open();
      },
      async onExit(_error, _metadata) {
        data.onReloginFailed && data.onReloginFailed();
      },
      async onSuccess() {
        if (!data.fundingSourceId) return;
        data.onRelogin && data.onRelogin();
      }
    };
  }, [linkToken, data]);
  const getPlaidLink = useStateInRef(usePlaidLink(options));
  return function reloginPlaidBankAccount(options) {
    const {
      fundingSourceId,
      relationshipId
    } = options;
    if (!fundingSourceId) return;
    logBI(10823, {
      plaid: true
    });
    setData(options);
    overlayContext.startLoader(linkTokenLoader);
    handlePromise(api.plaid.getLinkToken(PlaidMode.Customer, {
      fundingSourceId,
      relationshipId
    })).then(token => {
      if (!token.success) {
        overlayContext.stopLoader(linkTokenLoader);
        overlayContext.showErrorToast('Failed to initialize, please try again.', 'link-token-error');
      } else {
        setLinkToken(token.data);
      }
    });
  };
}