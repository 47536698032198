import { BillMode } from "../../../../components/ManualBillModal/ManualBillModal.utils";
import { manualServiceBillingModel, serviceCostModel, serviceModel } from "../../../../models";
import { isBefore } from 'date-fns';
export function getSuccessToastText(mode, serviceName) {
  switch (mode) {
    case BillMode.Now:
      return "Service ".concat(serviceName, " billed successfully");
    case BillMode.Upcoming:
      return "Service ".concat(serviceName, " was added to the upcoming bill");
    case BillMode.FutureDate:
      return "Service ".concat(serviceName, " was added to a future date");
  }
}
export function getFailureToastText(mode, serviceName) {
  switch (mode) {
    case BillMode.Now:
      return "Failed to bill service ".concat(serviceName);
    case BillMode.Upcoming:
      return "Failed to add service ".concat(serviceName, " to the upcoming bill");
    case BillMode.FutureDate:
      return "Failed to add service ".concat(serviceName, " to a future date");
  }
}
export function getRelevantPeriod(manualServiceBilling, issueDate) {
  if (!manualServiceBilling) return undefined;
  return manualServiceBilling.periods.find(p => manualServiceBillingModel.isInRange(issueDate, p));
}
export function getCumulativeWarning(service, issueDate, manualServiceBilling) {
  const relevantPeriod = getRelevantPeriod(manualServiceBilling, issueDate);
  if (!relevantPeriod) {
    return null;
  } else if (serviceCostModel.isVariablePrice(service.cost)) {
    const unitCap = serviceModel.unitCap(service);
    if (!unitCap) {
      return null;
    }
    const {
      billedQuantity,
      scheduledQuantity
    } = relevantPeriod;
    const isWarning = billedQuantity.plus(scheduledQuantity).gt(unitCap);
    return {
      isWarning,
      billedQuantity,
      scheduledQuantity
    };
  } else {
    const {
      billedQuantity,
      scheduledQuantity
    } = relevantPeriod;
    return billedQuantity.gt(0) || scheduledQuantity.gt(0) ? {
      isWarning: true,
      billedQuantity,
      scheduledQuantity
    } : null;
  }
}
export function getBillServiceBeforeItsEffectiveDateWarning(mode, service, issueDate) {
  const effectiveDate = serviceModel.effectiveDate(service);
  if (mode === BillMode.Now && effectiveDate && isBefore(issueDate, effectiveDate)) {
    return {
      text: 'As the issue date is earlier than the date this service was added to the agreement, your client will need to manually approve the payment.'
    };
  }
  return null;
}