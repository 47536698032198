export function makeScrollToBottom(scrollToBottom) {
  return {
    scrollToBottom
  };
}
export function makeOpenAccountantAccessRequest(openAccountantAccessRequest) {
  return {
    openAccountantAccessRequest
  };
}
export function makeAfterEmailVerification(afterEmailVerification) {
  return {
    afterEmailVerification
  };
}