function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { notEmpty } from "../../infra/utils";
import { serviceModel } from "./..";
import { ApprovalMethodDateModel } from "../ApprovalMethodDateModel";
export function fromDTO(dto) {
  return _objectSpread(_objectSpread({}, dto), {}, {
    serviceBundles: dto.serviceBundles ? {
      bundles: dto.serviceBundles.bundles.map(b => _objectSpread(_objectSpread({}, b), {}, {
        services: b.services.map(serviceModel.fromDTO).filter(notEmpty)
      }))
    } : undefined,
    services: dto.services.map(serviceModel.fromDTO).filter(notEmpty),
    changes: dto.changes ? {
      netTerms: changeFromDTO(dto.changes.netTerms),
      amendmentsApprovalMethod: changeFromDTO(dto.changes.amendmentsApprovalMethod),
      allowManualPayments: changeFromDTO(dto.changes.allowManualPayments),
      invoicesApprovalMethod: changeFromDTO(dto.changes.invoicesApprovalMethod)
    } : null
  });
}
export function toDTO(relationship) {
  return _objectSpread(_objectSpread({}, relationship), {}, {
    serviceBundles: relationship.serviceBundles ? {
      bundles: relationship.serviceBundles.bundles.map(b => _objectSpread(_objectSpread({}, b), {}, {
        services: b.services.map(serviceModel.toDTO).filter(notEmpty)
      }))
    } : undefined,
    services: relationship.services.map(serviceModel.toDTO).filter(notEmpty),
    changes: relationship.changes ? {
      netTerms: changeToDTO(relationship.changes.netTerms),
      amendmentsApprovalMethod: changeToDTO(relationship.changes.amendmentsApprovalMethod),
      allowManualPayments: changeToDTO(relationship.changes.allowManualPayments)
    } : null
  });
}
function changeFromDTO(dto) {
  return dto ? _objectSpread(_objectSpread({}, dto), {}, {
    approvalMethod: ApprovalMethodDateModel.fromDTO(dto.approvalMethod)
  }) : undefined;
}
function changeToDTO(change) {
  return change ? _objectSpread(_objectSpread({}, change), {}, {
    approvalMethod: change.approvalMethod.toDTO()
  }) : undefined;
}