function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { ServiceStatusType } from "./model";
import * as serviceBillingModel from "../service-billing";
import { utils } from "../../utils";
import * as makers from "./makers";
import * as selectors from "./selectors";
import { defaultBillingDayOfWeek } from "../../constants";
import { BillingDayOfMonthModel } from "../BillingDayOfMonth";
import { serviceCostModel, serviceTemplateModel, timeModel } from '..';
import { ApprovalMethodDateModel } from "../ApprovalMethodDateModel";
import { ServiceIntegrationsModel } from "../ServiceIntegrations";
import { makeBillUpfrontState, makeOnApprovalBilling, makeOneTimeManualBilling, makeOngoingBilling, makeRepeatableManualBilling } from "../service-billing/makers";
export function fromDTO(service) {
  const status = statusFromDTO(service.status);
  const billing = serviceBillingModel.fromDTO(service.billing, selectors.isAwaitingApproval(status));
  const cost = serviceCostModel.fromDTO(service.cost);
  if (billing != null) {
    return makers.makeService(_objectSpread(_objectSpread({}, service), {}, {
      billing,
      cost,
      status,
      participation: !service.participation.type ? makers.makeMandatoryParticipation() : service.participation,
      integrations: ServiceIntegrationsModel.fromDTO(service.integrations)
    }));
  }
}
export function toDTO(s) {
  const dto = {
    id: s.id,
    name: s.name,
    description: s.description,
    serviceTemplateId: s.serviceTemplateId,
    participation: s.participation,
    isBillable: s.isBillable,
    cost: serviceCostModel.toDTO(s.cost),
    billing: serviceBillingModel.toDTO(s.billing),
    status: statusToDTO(s.status)
  };
  if (s.integrations.hasIntegrations()) {
    dto.integrations = s.integrations.toJSON();
  }
  return dto;
}
export function fromServiceTemplate(template) {
  var _context$billingDayOf, _context$billingDayOf2, _context$isPaused, _context$prorateFirst, _context$isBilledUpfr, _context$billingDayOf3, _context$billingDayOf4, _context$isBilledUpfr2;
  let context = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const {
    id,
    integratedId,
    name,
    description,
    billing,
    cost
  } = template;
  return makers.makeService({
    name,
    description,
    integratedId: integratedId || undefined,
    serviceTemplateId: id,
    integrations: template.integrations,
    status: makers.makeAwaitingApprovalStatus(),
    cost,
    billing: serviceTemplateModel.isOnApproval(billing) ? makeOnApprovalBilling() : serviceTemplateModel.isOneTimeManual(billing) ? makeOneTimeManualBilling() : serviceTemplateModel.isOngoing(billing) ? makeOngoingBilling(billing.recurrencePeriod, {
      billingDayOfMonth: (_context$billingDayOf = context.billingDayOfMonth) !== null && _context$billingDayOf !== void 0 ? _context$billingDayOf : BillingDayOfMonthModel.make(),
      billingDayOfWeek: (_context$billingDayOf2 = context.billingDayOfWeek) !== null && _context$billingDayOf2 !== void 0 ? _context$billingDayOf2 : defaultBillingDayOfWeek,
      isPaused: (_context$isPaused = context.isPaused) !== null && _context$isPaused !== void 0 ? _context$isPaused : false,
      isProrated: (_context$prorateFirst = context.prorateFirstInvoice) !== null && _context$prorateFirst !== void 0 ? _context$prorateFirst : false,
      isBilledUpfront: (_context$isBilledUpfr = context.isBilledUpfront) !== null && _context$isBilledUpfr !== void 0 ? _context$isBilledUpfr : false,
      billUpfrontState: context.isBilledUpfront ? makeBillUpfrontState(context.skipBillingOnAcceptance || !!context.isPaused) : undefined
    }) : makeRepeatableManualBilling(billing.recurrencePeriod, {
      billingDayOfMonth: (_context$billingDayOf3 = context.billingDayOfMonth) !== null && _context$billingDayOf3 !== void 0 ? _context$billingDayOf3 : BillingDayOfMonthModel.make(),
      billingDayOfWeek: (_context$billingDayOf4 = context.billingDayOfWeek) !== null && _context$billingDayOf4 !== void 0 ? _context$billingDayOf4 : defaultBillingDayOfWeek,
      isBilledUpfront: (_context$isBilledUpfr2 = context.isBilledUpfront) !== null && _context$isBilledUpfr2 !== void 0 ? _context$isBilledUpfr2 : false
    })
  });
}
export function statusFromDTO(dto) {
  if (dto.type === ServiceStatusType.AwaitingApproval) {
    return {
      type: dto.type,
      awaitingApproval: {
        addedOn: timeModel.fromDatestampObject(dto.awaitingApproval.addedOn),
        approvalMethod: ApprovalMethodDateModel.fromDTO(dto.awaitingApproval.approvalMethod),
        note: dto.awaitingApproval.note
      }
    };
  } else if (dto.type === ServiceStatusType.Amended) {
    return {
      type: dto.type,
      date: timeModel.fromDatestampObject(dto.date),
      amended: {
        amendedOn: timeModel.fromDatestampObject(dto.amended.amendedOn),
        approvalMethod: ApprovalMethodDateModel.fromDTO(dto.amended.approvalMethod),
        note: dto.amended.note,
        changes: changesFromDTO(dto.amended.changes)
      }
    };
  } else if (dto.type === ServiceStatusType.Canceled) {
    return {
      type: dto.type
    };
  } else if (dto.type === ServiceStatusType.Completed) {
    const obj = {
      type: dto.type,
      date: timeModel.fromDatestampObject(dto.date),
      completed: {}
    };
    if (dto.completed.changes) {
      obj.completed.changes = changesFromDTO(dto.completed.changes);
    }
    return obj;
  } else {
    return {
      type: dto.type,
      date: timeModel.fromDatestampObject(dto.date)
    };
  }
}
export function statusToDTO(status) {
  if (status.type === ServiceStatusType.AwaitingApproval) {
    return {
      type: status.type,
      awaitingApproval: {
        addedOn: timeModel.toDatestampObject(status.awaitingApproval.addedOn),
        approvalMethod: status.awaitingApproval.approvalMethod.toDTO(),
        note: status.awaitingApproval.note
      }
    };
  } else if (status.type === ServiceStatusType.Amended) {
    return {
      type: status.type,
      date: timeModel.toDatestampObject(status.date),
      amended: {
        amendedOn: timeModel.toDatestampObject(status.amended.amendedOn),
        approvalMethod: status.amended.approvalMethod.toDTO(),
        note: status.amended.note,
        changes: changesToDTO(status.amended.changes)
      }
    };
  } else if (status.type === ServiceStatusType.Canceled) {
    return {
      type: status.type
    };
  } else if (status.type === ServiceStatusType.Completed) {
    const dto = {
      type: status.type,
      date: timeModel.toDatestampObject(status.date),
      completed: {}
    };
    if (status.completed.changes) {
      dto.completed.changes = changesToDTO(status.completed.changes);
    }
    return dto;
  } else {
    return {
      type: status.type,
      date: timeModel.toDatestampObject(status.date)
    };
  }
}
export function toDBService(service) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const {
    cost
  } = service;
  return {
    id: service.id,
    name: service.name,
    description: service.description,
    billedUpon: serviceBillingModel.toDBServiceBillingTrigger(service.billing),
    cost: {
      pricingType: serviceCostModel.isFixedPrice(cost) ? {
        type: cost.pricingType,
        fixed: {
          price: utils.dollarsToCents(cost.price)
        },
        variable: null,
        range: null
      } : serviceCostModel.isVariablePrice(cost) ? {
        type: cost.pricingType,
        fixed: null,
        variable: {
          unitPrice: utils.dollarsToCents(cost.price),
          cap: cost.unitCap != null ? utils.dollarsToCents(cost.unitCap) : options.forceZeroCap ? 0 : null,
          name: cost.unitName
        },
        range: null
      } : {
        type: cost.pricingType,
        fixed: null,
        variable: null,
        range: {
          minPrice: cost.minPrice && utils.dollarsToCents(cost.minPrice),
          maxPrice: utils.dollarsToCents(cost.maxPrice)
        }
      },
      discount: service.cost.discount && service.cost.discount.toDBDTO(),
      priceIncrease: cost.priceIncrease.toDB()
    },
    note: options.note || null,
    status: selectors.isTerminated(service.status) || selectors.isCanceled(service.status) ? service.status.type : undefined,
    serviceTemplateId: service.serviceTemplateId,
    participation: !service.participation.type ? makers.makeMandatoryParticipation() : service.participation
  };
}
function changesFromDTO(changesDTO) {
  return _objectSpread(_objectSpread({}, changesDTO), {}, {
    billing: changesDTO.billing && serviceBillingModel.fromDTO(changesDTO.billing) || null,
    cost: changesDTO.cost && serviceCostModel.fromDTO(changesDTO.cost)
  });
}
function changesToDTO(changes) {
  return _objectSpread(_objectSpread({}, changes), {}, {
    cost: changes.cost ? serviceCostModel.toDTO(changes.cost) : null,
    billing: changes.billing ? serviceBillingModel.toDTO(changes.billing) : null
  });
}