import { AccountNotificationType, BusinessNotificationType, InvoiceNotificationType, NotificationType, RelationshipNotificationType } from "../../types/api/notification.model";
import { routes } from "../../constants";
import { getURLWithFullPageInvoice } from "../../components/FullPageInvoice/FullPageInvoice.utils";
import { makeScrollToBottom, makeAfterEmailVerification, makeOpenAccountantAccessRequest } from "../../utils/location-state";
import { NotificationActionType } from "../NotificationAction/NotificationActionTypes";
const unrecognizedNotification = 'unrecognized notification';
const defaultRedirect = {
  url: routes.home
};
const makeBadNotificationPayloadRedirect = () => ({
  error: 'incorrect notification payload',
  redirect: defaultRedirect
});
export function notificationRouter(_ref) {
  var _notification$account;
  let {
    notification,
    featureFlags = {}
  } = _ref;
  switch (notification.type) {
    case NotificationType.Relationship:
      {
        if (!notification.relationship.id) {
          return makeBadNotificationPayloadRedirect();
        }
        switch (notification.notificationType) {
          case RelationshipNotificationType.AdHocChargeAdded:
          case RelationshipNotificationType.AdHocChargeCanceled:
          case RelationshipNotificationType.AdHocChargeApprovalReminder:
          case RelationshipNotificationType.CreditApplied:
            return {
              redirect: {
                url: routes.billingHub(notification.relationship.id)
              }
            };
          case RelationshipNotificationType.Amended:
          case RelationshipNotificationType.AmendedNoClientApproval:
          case RelationshipNotificationType.Approved:
          case RelationshipNotificationType.RelationshipApprovedVendor:
          case RelationshipNotificationType.ApprovedAmendments:
          case RelationshipNotificationType.RelationshipAmendmentsApprovedVendor:
          case RelationshipNotificationType.ClientUnresponsiveAmendmentsApproval:
          case RelationshipNotificationType.ClientUnresponsiveSigning:
          case RelationshipNotificationType.AgreementAmendmentsReminder:
          case RelationshipNotificationType.ClientApprovedRelationship:
          case RelationshipNotificationType.Terminated:
          case RelationshipNotificationType.RelationshipUpdated:
          case RelationshipNotificationType.RelationshipUpdatedWithChanges:
          case RelationshipNotificationType.AmendmentsAutoApprovalReminder:
          case RelationshipNotificationType.AmendmentsAutoApproved:
          case RelationshipNotificationType.RelationshipTerminated:
          case RelationshipNotificationType.RelationshipAutoPriceIncreaseVendor:
          case RelationshipNotificationType.RelationshipAutoPriceIncreaseCustomer:
          case RelationshipNotificationType.RelationshipPracticeManagementFailedItems:
            return {
              redirect: {
                url: routes.agreement(notification.relationship.id)
              }
            };
          case RelationshipNotificationType.ClientUnresponsivePaymentMethod:
          case RelationshipNotificationType.MicroDepositCompletionReminder:
          case RelationshipNotificationType.MicroDepositProcessInitiated:
          case RelationshipNotificationType.RequestPaymentMethod:
            return {
              redirect: {
                url: routes.paymentMethods(notification.relationship.id)
              }
            };
          case RelationshipNotificationType.RelationshipDirectLink:
            {
              const relationshipEntity = notification.relationship.directLinkData;
              let url = routes.home;
              if (relationshipEntity) {
                if ('invoice' in relationshipEntity) {
                  const invoiceId = relationshipEntity.invoice;
                  const relationshipId = notification.relationship.id;
                  url = getURLWithFullPageInvoice({
                    pathname: routes.invoicesDashboard,
                    invoiceId,
                    relationshipId
                  });
                } else if ('agreement' in relationshipEntity) {
                  url = routes.agreement(notification.relationship.id);
                }
              }
              return {
                redirect: {
                  url
                }
              };
            }
          case RelationshipNotificationType.Added:
          case RelationshipNotificationType.New:
          case RelationshipNotificationType.NewWithText:
          case RelationshipNotificationType.ExistingCustomer:
          case RelationshipNotificationType.AddedWithCustomText:
          case RelationshipNotificationType.SingleUseApproval:
          case RelationshipNotificationType.ExistingCustomerWithText:
          case RelationshipNotificationType.RelationshipNewProposalCustomer:
          case RelationshipNotificationType.AgreementApprovalReminder:
          case RelationshipNotificationType.AgreementApprovalReminderManual:
          case RelationshipNotificationType.RelationshipSigning:
          case RelationshipNotificationType.RelationshipReadyForReview:
          case RelationshipNotificationType.ReviewProposalLink:
          case RelationshipNotificationType.ProposalApprovalManualReminder:
            switch (notification.relationship.status) {
              case 'pending':
                return {
                  redirect: {
                    url: routes.clientOnboarding(notification.relationship.id)
                  }
                };
              case 'canceled':
                {
                  const {
                    type,
                    value
                  } = notification.relationship.detailedStatus;
                  switch (type) {
                    case 'canceledWithSingle':
                      return {
                        redirect: {
                          url: routes.clientOnboarding(value === null || value === void 0 ? void 0 : value.relationshipId)
                        }
                      };
                    case 'canceledWithMulti':
                      return {
                        redirect: {
                          url: routes.proposalsDashboard
                        }
                      };
                    case 'canceledWithZero':
                    default:
                      return {
                        redirect: {
                          url: routes.canceledProposalPage
                        }
                      };
                  }
                }
              default:
                return {
                  redirect: {
                    url: routes.clientOnboarding(notification.relationship.id)
                  }
                };
            }
          case RelationshipNotificationType.ApproveAmendment:
            if (featureFlags.USE_NEW_VENDOR_TO_CUSTOMER_AMENDMENTS_APPROVE_FROM_EMAIL_TEMPLATE && notification.relationship.approvalToken) {
              const state = {
                type: NotificationActionType.ApproveAmendment,
                token: notification.relationship.approvalToken,
                vendorName: notification.relationship.vendorName
              };
              return {
                redirect: {
                  url: routes.notificationAction,
                  state
                }
              };
            }
            return {
              error: unrecognizedNotification,
              redirect: {
                url: routes.agreement(notification.relationship.id)
              }
            };
          default:
            return {
              error: unrecognizedNotification,
              redirect: {
                url: routes.agreement(notification.relationship.id)
              }
            };
        }
      }
    case NotificationType.Invoice:
      {
        if (!notification.invoice.relationshipId) {
          return makeBadNotificationPayloadRedirect();
        }
        const {
          relationshipId,
          id: invoiceId,
          hasActivePaymentMethod = false
        } = notification.invoice;
        switch (notification.notificationType) {
          case InvoiceNotificationType.InvoiceIssued:
            return {
              redirect: {
                url: hasActivePaymentMethod ? getURLWithFullPageInvoice({
                  pathname: routes.invoicesDashboard,
                  invoiceId,
                  relationshipId
                }) : routes.paymentMethods(relationshipId)
              }
            };
          case InvoiceNotificationType.InvoiceIssuedOLD:
          case InvoiceNotificationType.InvoiceIssuedRequiresApproval:
          case InvoiceNotificationType.PaidCustomer:
          case InvoiceNotificationType.PaymentProcessingCustomer:
          case InvoiceNotificationType.PaymentProcessingVendor:
          case InvoiceNotificationType.UpcomingForImmediatePayment:
          case InvoiceNotificationType.ExtensionRequested:
          case InvoiceNotificationType.OneOffIssuedRequiresApproval:
          case InvoiceNotificationType.InvoiceApproved:
          case InvoiceNotificationType.ProcessingActionPending:
          case InvoiceNotificationType.InvoiceIssuedForPayment:
            return {
              redirect: {
                url: routes.paymentMethods(relationshipId)
              }
            };
          case InvoiceNotificationType.PaymentFailed:
            return {
              redirect: {
                url: routes.agreement(relationshipId),
                state: makeScrollToBottom(true)
              }
            };
          case InvoiceNotificationType.InvoicePaymentStoppedCustomer:
          case InvoiceNotificationType.InvoicePaymentStoppedVendor:
          case InvoiceNotificationType.InvoicePaymentResumedVendor:
          case InvoiceNotificationType.InvoicePaymentResumedCustomer:
          case InvoiceNotificationType.InvoiceRefundedVendor:
          case InvoiceNotificationType.InvoiceRefundFailedVendor:
          case InvoiceNotificationType.InvoiceRefundedCustomer:
          case InvoiceNotificationType.PaymentDisputedVendor:
          case InvoiceNotificationType.PaymentFailedCustomer:
          case InvoiceNotificationType.PaymentFailedVendor:
            return {
              redirect: {
                url: getURLWithFullPageInvoice({
                  pathname: routes.invoicesDashboard,
                  invoiceId,
                  relationshipId
                })
              }
            };
          case InvoiceNotificationType.ApproveInvoice:
            if (featureFlags.USE_NEW_VENDOR_TO_CUSTOMER_INVOICE_APPROVAL_TEMPLATE && notification.invoice.approvalToken) {
              const state = {
                type: NotificationActionType.ApproveInvoice,
                token: notification.invoice.approvalToken,
                vendorName: notification.invoice.vendorName,
                invoiceFormattedNumber: notification.invoice.invoiceFormattedNumber
              };
              return {
                redirect: {
                  url: routes.notificationAction,
                  state
                }
              };
            }
            return {
              error: unrecognizedNotification,
              redirect: {
                url: routes.invoices(relationshipId)
              }
            };
          default:
            return {
              error: unrecognizedNotification,
              redirect: {
                url: routes.invoices(relationshipId)
              }
            };
        }
      }
    case NotificationType.Account:
      {
        switch (notification.notificationType) {
          case AccountNotificationType.EmailVerification:
            return {
              redirect: {
                url: routes.home,
                state: makeAfterEmailVerification(true)
              },
              hubspotEventName: 'notification_open_emailverification'
            };
          case AccountNotificationType.ContinueVendorOnboarding:
          case AccountNotificationType.EmailUpdate:
          case AccountNotificationType.OneTimeAccess:
          case AccountNotificationType.VerificationPassed:
          case AccountNotificationType.PasswordResetSuccessfully:
            return {
              redirect: {
                url: routes.home
              }
            };
          case AccountNotificationType.ResetPasswordRequested:
          case AccountNotificationType.InviteToJoinBusiness:
            if ((_notification$account = notification.account) !== null && _notification$account !== void 0 && _notification$account.user) {
              // Redirect to login page with email pre-filled.
              return {
                redirect: {
                  url: routes.home
                }
              };
            }
            return {
              redirect: {
                url: routes.setPassword
              }
            };
          default:
            return {
              error: unrecognizedNotification,
              redirect: {
                url: routes.home
              }
            };
        }
      }
    case NotificationType.Business:
      {
        switch (notification.notificationType) {
          case BusinessNotificationType.ReminderConnectKyc:
          case BusinessNotificationType.PayoutReconciliation:
          case BusinessNotificationType.PaymentsDigest:
            return {
              redirect: {
                url: routes.vendorDashboard
              }
            };
          case BusinessNotificationType.AccountantJoinRequest:
            return {
              redirect: {
                url: routes.team,
                state: makeOpenAccountantAccessRequest(true)
              }
            };
          case BusinessNotificationType.AccountantJoinRequestApproved:
          case BusinessNotificationType.AccountantJoinRequestRejected:
            return {
              redirect: {
                url: routes.contacts
              }
            };
          case BusinessNotificationType.AccountantPmAdded:
            return {
              redirect: {
                url: routes.agreementsDashboard
              }
            };
          case BusinessNotificationType.ReferringVendorCreditsAwarded:
            return {
              redirect: {
                url: routes.referrals
              }
            };
          case BusinessNotificationType.ReferredVendorCreditsAwarded:
            return {
              redirect: {
                url: routes.home
              }
            };
          case BusinessNotificationType.PayoutDepositedVendor:
          case BusinessNotificationType.PayoutFailedVendor:
          case BusinessNotificationType.PayoutDepositedRetryVendor:
            return {
              redirect: {
                url: routes.payoutsDashboard
              }
            };
          case BusinessNotificationType.ManualMicroDepositInitiatedCustomer:
          case BusinessNotificationType.ManualMicroDepositInitiatedVendor:
            return {
              redirect: {
                url: routes.home
              }
            };
          case BusinessNotificationType.AdditionalPaymentProcessorInformationNeeded:
            {
              const {
                additionalPaymentProcessorInformationNeeded
              } = notification.business || {};
              if (!additionalPaymentProcessorInformationNeeded) {
                return makeBadNotificationPayloadRedirect();
              }
              const state = {
                data: additionalPaymentProcessorInformationNeeded
              };
              return {
                redirect: {
                  url: routes.additionalPaymentProcessorInfo,
                  state
                }
              };
            }
          case BusinessNotificationType.TaxForm1099Vendor:
            {
              return {
                redirect: {
                  url: routes.businessDetails
                }
              };
            }
          default:
            return {
              error: unrecognizedNotification,
              redirect: {
                url: routes.home
              }
            };
        }
      }
    default:
      return {
        error: unrecognizedNotification,
        redirect: defaultRedirect
      };
  }
}