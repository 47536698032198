import { addYears, isSameDay, startOfDay } from 'date-fns';
import { BillMode } from "../components/ManualBillModal/ManualBillModal.utils";
import { utils } from "../utils";
export function makeInvoiceDateInfo(invoiceDraftDates, selectedDate) {
  if (!invoiceDraftDates.dates().length) {
    throw new Error('futureInvoicesDates.length is 0');
  }
  const actualSelectedDate = selectedDate || invoiceDraftDates.dates()[0];
  return {
    invoiceDraftDates,
    selectedDate: actualSelectedDate,
    isUpcomingSelected: isSameDay(actualSelectedDate, invoiceDraftDates.dates()[0])
  };
}
export function getCreditsWarning(mode, totalCredits) {
  if (mode === BillMode.Now && totalCredits !== null && totalCredits !== void 0 && totalCredits.gt(0)) {
    return {
      text: "Your client has ".concat(utils.formatDollarAmount(totalCredits), " in credit. To prevent credits from automatically being applied to invoices, disable them in the Billing Control.")
    };
  }
  return null;
}
export function getMaxFutureInvoiceDate(now) {
  return addYears(startOfDay(now), 1);
}