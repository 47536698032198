import { useCallback, useState } from 'react';
import { useOverlayContext } from 'seagull';
import { useStoreActions } from "../contexts";
import { routes } from "../constants";
import { handlePromise } from "../infra/utils";
import { useOpenCopyLinkModal } from "./useOpenCopyLinkModal/useOpenCopyLinkModal";
export function useCopyDirectLink() {
  const {
    api
  } = useStoreActions();
  const overlayContext = useOverlayContext();
  const {
    openCopyLinkForAgreement
  } = useOpenCopyLinkModal();
  const [isFetchingLink, setIsFetchingLink] = useState(false);
  const copyDirectLink = useCallback(async (relationshipId, data) => {
    setIsFetchingLink(true);
    const result = await handlePromise(api.relationship.getDirectLink(relationshipId, data));
    if (!result.success) {
      setIsFetchingLink(false);
      overlayContext.showErrorToast('Failed to generate link, please try again', 'direct-link-error-toast');
      return '';
    }
    setIsFetchingLink(false);
    const url = "https://".concat(window.location.host).concat(routes.notification(result.data));
    await openCopyLinkForAgreement({
      url,
      testid: 'copy-direct-link-copy'
    });
    return result.data;
  }, []);
  return {
    isFetchingLink,
    copyDirectLink
  };
}