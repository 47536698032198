import { ErrorText } from "../constants";
import { useOverlayContext, useStoreActions } from "../contexts";
import { ServerError } from "../infra/ErrorResponse";
import { handleRequest } from "../infra/utils";
export function useApproveAmendments() {
  const {
    api
  } = useStoreActions();
  const overlayContext = useOverlayContext();
  return async function approveAmendments(_ref) {
    let {
      id,
      version,
      latestAmendmentVersion,
      hideSuccessToast,
      throwOnError,
      onSuccess
    } = _ref;
    const approve = await handleRequest(api.relationship.approveAllAmendments(id, version, latestAmendmentVersion));
    if (!approve.success) {
      const errorMsg = approve.error.hasServerError(ServerError.MismatchingRelationshipVersion) ? ErrorText.MismatchingRelationshipVersion : approve.error.hasServerError(ServerError.InvalidRelationshipStatus) ? 'The agreement was terminated' : 'Failed to approve amendments, please try again';
      overlayContext.showErrorToast(errorMsg, 'approve-amendments-error');
      if (throwOnError) throw approve.error;
    } else {
      onSuccess && (await onSuccess());
      !hideSuccessToast && overlayContext.showSuccessToast('Approved amendments successfully', 'approve-amendments-success');
    }
  };
}