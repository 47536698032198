import { useMemo } from 'react';
import { useOverlayContext, useStore, useStoreActions } from "../../../contexts";
import { useEventReporter } from "../../../event-reporter";
import { handlePromise } from "../../../infra/utils";
import { selectors } from "../../../store/state-selectors";
export const NEW_BUSINESS_NAME_FLAG = 'new-business-name';
export const changeBusinessLoader = 'changeBusiness';
export function useWelcomeStepUtils(_ref) {
  let {
    relationship
  } = _ref;
  const overlayContext = useOverlayContext();
  const {
    reportToGtm,
    reportToAppcues,
    reportToHeap
  } = useEventReporter();
  const worksAt = useStore(selectors.user.business);
  const workspaces = useStore(selectors.user.workspaces);
  const {
    api,
    setAuthToken,
    logBI
  } = useStoreActions();
  async function associateBusinessAndSwitchWorkspace(customerId) {
    const associateCustomer = await handlePromise(api.relationship.associateCustomer(relationship.id, customerId));
    if (!associateCustomer.success) {
      throw new Error('Failed to create new business, please try again');
    }
    const switchBusiness = await handlePromise(api.user.switchBusiness(customerId));
    if (!switchBusiness.success) {
      throw new Error('Failed to switch to the new business created, please refresh and try selecting it from the list');
    }
    setAuthToken(switchBusiness.data);
  }
  async function createOrSelectBusiness(selectedWorkspace) {
    overlayContext.startLoader(changeBusinessLoader);
    try {
      if (selectedWorkspace === NEW_BUSINESS_NAME_FLAG && relationship.suggestedCustomerName) {
        const newBusiness = await handlePromise(api.user.createNewClientBusiness(relationship.suggestedCustomerName));
        if (!newBusiness.success) {
          throw new Error('Failed to create new business, please try again');
        }
        const newCustomerId = newBusiness.data.businessId;
        logBI(10354, {
          businessName: relationship.suggestedCustomerName
        });
        await associateBusinessAndSwitchWorkspace(newCustomerId);
        reportSwitchedBusiness(relationship.suggestedCustomerName);
      } else if (selectedWorkspace && selectedWorkspace !== (worksAt === null || worksAt === void 0 ? void 0 : worksAt.id)) {
        const workspace = workspaces === null || workspaces === void 0 ? void 0 : workspaces.find(workspace => workspace.id === selectedWorkspace);
        if (workspace) {
          logBI(10354, {
            businessName: workspace.name
          });
          await associateBusinessAndSwitchWorkspace(selectedWorkspace);
          reportSwitchedBusiness(workspace.name);
        }
      } else {
        overlayContext.stopLoader(changeBusinessLoader);
      }
    } catch (_unused) {
      overlayContext.stopLoader(changeBusinessLoader);
      overlayContext.showErrorToast('Error occurred, please try again or contact support@sayanchor.com for assistance', 'select-business-error');
    }
  }
  function reportSwitchedBusiness(businessName) {
    reportToGtm('button-click', 'client-switched-business', {
      businessName
    });
    reportToAppcues('button-click', 'client-switched-business');
    reportToHeap('clientOb', 'click', 'client-switched-business', {
      businessName
    });
  }
  const newBusinessWorkspace = useMemo(() => workspaces === null || workspaces === void 0 ? void 0 : workspaces.find(workspace => workspace.name === relationship.suggestedCustomerName), [workspaces, relationship]);
  return {
    createOrSelectBusiness,
    newBusinessWorkspace
  };
}