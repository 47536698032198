import { serviceBillingModel, serviceModel } from "../../../models";
import { BillingTriggerType } from "../../../types/api.model";
export function getBillingBadgeText(service, SERVICE_TEXT_ALIGNMENT) {
  const billing = serviceModel.billingTrigger(service);
  const trigger = serviceBillingModel.trigger(billing);
  const maxCharges = serviceModel.maxChargesEnabled(service) ? serviceModel.maxChargesLimit(service) : null;
  const recurrencePeriod = serviceBillingModel.isRecurring(billing) ? serviceModel.recurrencePeriod(billing) : null;
  switch (trigger) {
    case BillingTriggerType.OneTimeManual:
      return {
        text: 'Billed once',
        intent: 'onetime-manual'
      };
    case BillingTriggerType.OnApproval:
      return {
        text: SERVICE_TEXT_ALIGNMENT ? 'Billed once, on proposal acceptance' : 'Billed once, on agreement approval',
        intent: 'approval'
      };
    case BillingTriggerType.RepeatableManual:
    case BillingTriggerType.Ongoing:
      return {
        text: "Billed ".concat(recurrencePeriod).concat(maxCharges != null ? ", ".concat(maxCharges, " times") : ''),
        intent: 'recurring'
      };
  }
}